import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'chat-sign-in',
  mixins: [validationMixin],
  components: {
  },
  data() {
    return {
      payload: {
        email: '',
        password: '',
        utm_medium: '',
        utm_source: '',
        site_url: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      password: {
        required,
        maxLength: maxLength(30),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
    }
  },
  computed: {
    ...mapGetters({
      userLogin: 'translations/userLogin',
    }),

    disableButton() {
      return this.payload.password === '' || this.payload.email === '';
    },

    passwordErrors() {
      let error = [];

      if (!this.$v.payload.password.$dirty) {
        return error;
      }

      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }

      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }

      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },

    emailErrors() {
      let error = [];

      if (!this.$v.payload.email.$dirty) {
        return error;
      }

      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }

      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }

      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }

      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },

  },
  created() {
    this.setUtm();
  },
  methods: {
    ...mapActions({
      singInRequest: 'translations/TRANSLATIONS_USER_SIGN_IN_TRIGGER',
    }),

    ...mapMutations({
      changeChatRegistrationAvailabilityStatus: 'system/CHAT_REGISTRATION_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatSignInAvailabilityStatus: 'system/CHAT_SIGN_IN_POPUP_CHANGE_AVAILABILITY_STATUS',
      changeChatRestorePasswordAvailabilityStatus: 'system/CHAT_RESTORE_PASSWORD_POPUP_CHANGE_AVAILABILITY_STATUS',
    }),

    navigateToRegistration() {
      this.changeChatSignInAvailabilityStatus(false);

      setTimeout(() => {
        this.changeChatRegistrationAvailabilityStatus(true);
      }, 150)
    },

    changeRestore(){
      this.changeChatSignInAvailabilityStatus(false);

      setTimeout(() => {
        this.changeChatRestorePasswordAvailabilityStatus(true);
      }, 150);
    },
    closePopup(){
      this.changeChatSignInAvailabilityStatus(false);
    },
    submitHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.singInRequest(this.payload)
          .then(() => {
            this.changeChatSignInAvailabilityStatus(false);
            this.$toasted.success(this.$t('successAuth'));
          }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    },

    setUtm() {
      this.payload.utm_medium = this.$route.query.utm_medium;
      this.payload.utm_source = this.$route.query.utm_source;
      this.payload.site_url= window.location.origin+window.location.pathname;
    }
  }
}
